<template>
  <div>
    <b-row class="mt-2 mb-1">
      <h2 class="ml-1">
        Contact
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="email-edit">
          <template v-slot:label>
            <span class="required-field">Email</span>
          </template>
          <b-form-input
            v-model="email"
            class="text-uppercase"
            placeholder="jbloggs@email.com"
            name="email-edit"
            :state="emailState"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="mobile-phone-edit">
          <template v-slot:label>
            <span class="required-field">Mobile phone number</span>
          </template>
          <b-form-input
            v-model="mobilePhone"
            name="mobile-phone-edit"
            :state="mobileState"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Home phone number"
          label-for="home-phone-edit"
        >
          <b-form-input
            v-model="homePhone"
            name="home-phone-edit"
            placeholder=""
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'AppContactInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentApplication' }),
    ...mapGetters({ applicationValidation: 'app/getApplicationValidation' }),
    emailState() {
      console.log(this.applicationValidation)
      if (this.applicationValidation == null) return null

      if (this.email === null || this.email === undefined || this.email.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    email: {
      get() {
        return this.opInfo.email
      },
      set(value) {
        this.$store.commit('app/setApplicationEmail', value)
      },
    },
    mobileState() {
      console.log(this.applicationValidation)
      if (this.applicationValidation == null) return null

      if (this.mobilePhone === null || this.mobilePhone === undefined || this.mobilePhone.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    mobilePhone: {
      get() {
        return this.opInfo.mobphone
      },
      set(value) {
        this.$store.commit('app/setApplicationMobilePhone', value)
      },
    },
    homePhone: {
      get() {
        return this.opInfo.homephone
      },
      set(value) {
        this.$store.commit('app/setApplicationHomePhone', value)
      },
    },
  },
  created() {
  },
}
</script>

<style scoped>

</style>
