import axios from 'axios'

export default {
  validate(url, params) {
    return new Promise((resolve, reject) => {
      axios.post(`${url}captcha/`, params)
        .then(response => {
          console.log(response)
          if (response.data.hasErrors) {
            reject(response.data.message)
          } else {
            resolve(response.data)
          }
        })
        .catch(error => {
          if (Object.property.hasOwnProperty.call(error.response.data, 'hasErrors')) {
            reject(error.response.data.message)
          } else {
            reject(error.message)
          }
        })
    })
  },
}
