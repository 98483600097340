<template>
  <div class="mt-5">
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="trades-option">
          <template v-slot:label>
            <span class="required-field">Trade role you are applying for</span>
          </template>
          <b-form-select
            v-model="selectedTrade"
            name="trades-option"
            :state="tradeState"
          >
            <b-form-select-option
              v-for="trd in trades"
              :key="trd.trade_id"
              :value="trd.trade_id"
            >
              {{ trd.trade }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback :state="tradeState">
            Select a trade
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col
        sm="2"
        class="pr-0 mr-1"
      >
        <b-form-group label-for="CSCS-card-radio">
          <template v-slot:label>
            <span class="required-field">Do you have a CSCS card?</span>
          </template>
          <b-form-radio-group
            v-model="cscsCard"
            :options="cscsCardOptions"
            name="CSCS-card-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            :state="cscsCardState"
            required
            class="mt-1"
          />
          <b-form-invalid-feedback
            :state="cscsCardState"
            class="mt-1"
          >
            Select Yes or No
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label-for="start-date">
          <template v-slot:label>
            <span class="required-field">What date can you start work?</span>
          </template>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            selected-variant="primary"
            show-decade-nav
            reset-button
            close-button
            locale="en-GB"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            :state="startDateState"
            placeholder=""
          />
          <b-form-invalid-feedback :state="startDateState !== ''">
            Enter a start date
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-form-group>
          <div class="agreements">
            <b-form-checkbox
              id="bank-auth-checkbox"
              v-model="bankAuthCheck"
              class="mr-1"
              required
            >
              By ticking this box I agree that, if my application is successful, I will provide bank account information for payment, plus photos or scans of the following documents:<span style="color:red">*</span>
            </b-form-checkbox>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <ul>
          <li>CSCS card</li>
          <li>Photo ID (Passport / EU Card / Driving License / Provisional License</li>
        </ul>
        <b-form-invalid-feedback
          :state="!invalidBankAuth"
          class="mb-2"
        >
          You must agree to provide bank details when required to apply
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <div class="agreements">
            <b-form-checkbox
              id="terms-checkbox"
              v-model="termsCheck"
              required
              class="required-field"
            >
              <!-- eslint-disable -->
              By ticking this box I agree to the <a href="http://supplyconstruction.co.uk/wp-content/uploads/2018/10/Supply-Const-Website-Privacy-Policy.pdf" target="sclterms">Privacy</a> and <a href="http://supplyconstruction.co.uk/wp-content/uploads/2018/10/Supply-Const-GDPR-Policy.pdf">GDPR</a> policies
              <!-- eslint-enable -->
            </b-form-checkbox>
          </div>
          <b-form-invalid-feedback :state="!invalidTermsAuth">
            You must agree to the terms to apply
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col class="col-md-auto p-3 mb-2">
        <div class="text-center justify-center align-center">
          <captcha
            class="ml-auto mr-auto"
            @validate="validateCaptcha"
          />
        </div>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col class="p-0">
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="!captchaValidated"
            @click="submitApplicationClick()"
          >
            Submit
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BRow, BCol, BFormSelect, BFormCheckbox, BFormSelectOption, BFormInvalidFeedback, BButton, BFormDatepicker, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'
import Captcha from '@/components/general/Captcha.vue'
import jwt from '@/auth/jwt/sclUseJwt'

export default {
  name: 'AppSubmissionSection',
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BFormCheckbox,
    BFormSelectOption,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BFormRadioGroup,
    Captcha,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      recaptureKey: '6LeJGTQcAAAAABnEQcYUPJkCqRKVBZm6hnZ_Ywrd',
      captchaValidated: false,
      formInvalid: false,
      invalidEmployerCheck: false,
      invalidBankAuth: false,
      invalidTermsAuth: false,
      cscsCardOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
    }
  },
  computed: {
    ...mapGetters({ applicationValidation: 'app/getApplicationValidation' }),
    ...mapGetters({ opInfo: 'app/getCurrentApplication' }),
    ...mapGetters({ trades: 'app/getTrades' }),
    tradeName: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].tradename
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setApplicationTradeName', value)
      },
    },
    tradeState() {
      if (this.applicationValidation == null) return null

      if (this.selectedTrade === null || this.selectedTrade === undefined || this.selectedTrade < 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    selectedTrade: {
      get() {
        if (this.opInfo.trade_id === undefined) return -1
        return this.opInfo.trade_id
      },
      set(value) {
        this.$store.commit('app/setApplicationTradeId', value)
      },
    },
    cscsCardState() {
      if (this.applicationValidation == null) return null

      if (this.cscsCard === null || this.cscsCard === undefined) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }

      return true
    },
    cscsCard: {
      get() {
        console.log(this.opInfo.operativedetails[0].cscscard)
        return this.opInfo.operativedetails[0].cscscard
      },
      set(value) {
        console.log(`cscscard: ${value}`)
        this.$store.commit('app/setApplicationCSCS', value)
      },
    },
    startDateState() {
      if (this.applicationValidation == null) return null

      if (this.startDate === null || this.startDate === undefined || this.startDate.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    startDate: {
      get() {
        // New operative setup, so this will enforce a DOB
        if (this.opInfo.operativedetails[0].availability === 'new') {
          return ''
        }
        // Existing operatives will have null if no date set, to prevent it being compulsory setting blank date
        if (this.opInfo.operativedetails[0].availability === null) {
          return '    -  -  '
        }
        return this.opInfo.operativedetails[0].availability
      },
      set(value) {
        this.$store.commit('app/setApplicationAvailability', value)
      },
    },
    bankAuthCheck: {
      get() {
        return this.opInfo.bankinfo_auth
      },
      set(value) {
        if (value === true) {
          this.invalidBankAuth = false
        }
        this.$store.commit('app/setApplicationBankInfoAuth', value)
      },
    },
    termsCheck: {
      get() {
        return this.opInfo.terms_auth
      },
      set(value) {
        if (value === true) {
          this.invalidTermsAuth = false
        }
        this.$store.commit('app/setApplicationTermsAuth', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
  },
  methods: {
    validateCaptcha(success) {
      console.log(`Captcha result: ${success}`)
      this.captchaValidated = success
    },
    submit(response) {
      console.log(response)
    },
    showMessage(success, iconType, titleText, messageText) {
      swal.fire({
        title: titleText,
        text: messageText,
        icon: iconType,
        showConfirmButton: false,
        showCancelButton: success === false,
        showCloseButton: false,
        customClass: {
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    },

    async checksPassed() {
      if (this.bankAuthCheck === false) {
        this.invalidBankAuth = true
      }

      if (this.termsCheck === false) {
        this.invalidTermsAuth = true
      }

      if (!this.applicationValidation) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        this.$store.commit('app/resetApplicationValidation')
      }

      this.$store.commit('app/startApplicationValidation')
      console.log(this.applicationValidation)
      if (this.invalidBankAuth || this.invalidTermsAuth || this.invalidEmployerCheck || !this.applicationValidation) {
        this.showMessage(false, 'error', 'Error', 'To apply all required fields must be filled')
        return false
      }

      return true
    },

    async submitApplicationClick() {
      if (!await this.checksPassed()) return

      const data = JSON.stringify(this.opInfo)
      console.log(data)
      jwt.axiosIns.post(`/application/${this.opInfo.email}`, data)
        .then(response => {
          console.log(response.data)
          if (response.data.message !== undefined) {
            this.showMessage(false, 'error', 'Error', response.data.message)
          } else {
            this.showMessage(true, 'success', 'Thank you', 'Your application has been submitted. You\'ll be contacted shortly by one of our staff.')
            this.$store.commit('app/resetApplicationValidation')
            this.$store.commit('app/resetCurrentApplication')
          }
        })
        .catch(e => {
          this.showMessage(false, 'error', 'Error', 'There was an error creating the Operative. Please review for missing fields')
          console.error(e.response)
        })
    },
  },
}
</script>

<style scoped>
.agreements {
  margin-top: 0.3rem;
  display: flex;
}
</style>
