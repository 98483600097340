<template>
  <div>
    <b-row class="mt-3 mb-1">
      <h2 class="ml-1">
        Address
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="address-1-edit">
          <template v-slot:label>
            <span class="required-field">Address line 1</span>
          </template>
          <b-form-input
            v-model="addressLine1"
            class="text-uppercase"
            placeholder="25 Yellow Brick Road"
            name="address-1-edit"
            required
            :state="addressLine1State"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group
          label="Address line 2"
          label-for="address-2-edit"
        >
          <b-form-input
            v-model="addressLine2"
            class="text-uppercase"
            placeholder="Wimbledon"
            name="address-2-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="4">
        <b-form-group label-for="city-edit">
          <template v-slot:label>
            <span class="required-field">City</span>
          </template>
          <b-form-input
            v-model="city"
            class="text-uppercase"
            placeholder="London"
            name="city-edit"
            required
            :state="cityState"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mt-2 mb-1">
      <b-col sm="4">
        <b-form-group label-for="postcode-edit">
          <template v-slot:label>
            <span class="required-field">Post code</span>
          </template>
          <b-form-input
            v-model="postcode"
            class="text-uppercase"
            name="postcode-edit"
            placeholder=""
            required
            :state="postcodeState"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'AppAddressInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentApplication' }),
    ...mapGetters({ applicationValidation: 'app/getApplicationValidation' }),
    addressLine1: {
      get() {
        return this.opInfo.addressLine1
      },
      set(value) {
        this.$store.commit('app/setApplicationAddressLine1', value)
      },
    },
    addressLine1State() {
      if (this.applicationValidation == null) return null

      if (this.addressLine1 === null || this.addressLine1 === undefined || this.addressLine1.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    addressLine2: {
      get() {
        return this.opInfo.addressLine2
      },
      set(value) {
        this.$store.commit('app/setApplicationAddressLine2', value)
      },
    },
    city: {
      get() {
        return this.opInfo.city
      },
      set(value) {
        this.$store.commit('app/setApplicationCity', value)
      },
    },
    cityState() {
      if (this.applicationValidation == null) return null

      if (this.city === null || this.city === undefined || this.city.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    postcode: {
      get() {
        return this.opInfo.postcode
      },
      set(value) {
        this.$store.commit('app/setApplicationPostcode', value)
      },
    },
    postcodeState() {
      if (this.applicationValidation == null) return null

      if (this.postcode === null || this.postcode === undefined || this.postcode.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
  },
  watch: {

  },
  created() {
    this.isLoading = true
    console.log(`create loading: ${this.isLoading}`)
  },
  updated() {
    console.log(`updated state loading: ${this.isLoading}`)
    this.isLoading = false
  },
}
</script>

<style scoped>

</style>
