<template>
  <div>
    <b-row class="mt-3 mb-1">
      <h2 class="ml-1">
        Trading information
      </h2>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="UTR number"
          label-for="utr-number-edit"
        >
          <b-form-input
            v-model="utr"
            placeholder="1234567890"
            name="utr-number-edit"
          />
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group
          label="Worker type"
          label-for="worker-type-radio"
          required
        >
          <b-form-radio-group
            v-model="companyType"
            :options="workerTypeOptions"
            name="worker-type-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            :state="companyTypeState"
            required
            class="mt-1"
          />
          <b-form-invalid-feedback :state="companyTypeState">
            Select a worker type
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group
          label="Are you VAT registered?"
          label-for="vat-reg-radio"
          required
        >
          <b-form-radio-group
            v-model="vatReg"
            :options="yesNoOptions"
            name="vat-reg-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <b-form-group
          label="Trading name"
          label-for="trading-name-edit"
        >
          <b-form-input
            v-model="tradeName"
            name="trading-name-edit"
            class="text-uppercase"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label-for="company-reg-no-edit">
          <template v-slot:label>
            <span :class="companyTypeFieldsRequired ? 'required-field':''">Company registration number</span>
          </template>
          <b-form-input
            v-model="companyRegNo"
            placeholder=""
            name="company-reg-no-edit"
            :disabled="companyTypeFieldsRequired !== true"
            :required="companyTypeFieldsRequired"
            :state="companyRegState"
          />
        </b-form-group>
      </b-col>
      <b-col sm="2">
        <b-form-group label-for="company-vat-no-edit">
          <template v-slot:label>
            <span :class="vatReg ? 'required-field':''">Vat number</span>
          </template>
          <b-form-input
            v-model="compVatNo"
            placeholder=""
            name="company-vat-no-edit"
            :disabled="vatReg !== true"
            :required="vatReg"
            :state="VatNoState"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BFormInput, BRow, BCol, BFormRadioGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'AppTradingInfoSection',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      yesNoOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
      workerTypeOptions: [
        { item: 'soletrader', name: 'Sole trader' },
        { item: 'ltd', name: 'Limited company' },
      ],
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentApplication' }),
    ...mapGetters({ applicationValidation: 'app/getApplicationValidation' }),
    ...mapGetters({ trades: 'app/getTrades' }),

    companyTypeFieldsRequired() {
      if (this.companyType === undefined || this.companyType === 0 || this.companyType === 'soletrader') {
        return false
      }
      return true
    },
    tradeName: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].tradename
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setApplicationTradeName', value)
      },
    },
    VatNoState() {
      if (!this.vatReg) return null

      if (this.applicationValidation == null) return null

      if (this.compVatNo === null || this.compVatNo === undefined || this.compVatNo.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    compVatNo: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].vatno
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setApplicationVatNo', value)
      },
    },
    utr: {
      get() {
        if (this.opInfo.cisdetails[0] !== undefined) {
          return this.opInfo.cisdetails[0].utr
        }
        return ''
      },
      set(value) {
        this.$store.commit('app/setApplicationUtr', value)
      },
    },
    companyTypeState() {
      if (this.applicationValidation == null) return null

      if (this.companyType === null || this.companyType === undefined || this.companyType.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    companyType: {
      get() {
        return this.opInfo.comptype
      },
      set(value) {
        this.$store.commit('app/setApplicationCompanyType', value)
      },
    },
    vatReg: {
      get() {
        return this.opInfo.operativedetails[0].vatreg
      },
      set(value) {
        console.log(value)
        this.$store.commit('app/setApplicationVatReg', value)
      },
    },
    companyRegState() {
      if (!this.companyTypeFieldsRequired) return null

      if (this.applicationValidation == null) return null

      if (this.companyRegNo === null || this.companyRegNo === undefined || this.companyRegNo.length === 0) {
        this.$store.commit('app/failApplicationValidation')
        return false
      }
      return true
    },
    companyRegNo: {
      get() {
        return this.opInfo.comp_reg_no
      },
      set(value) {
        this.$store.commit('app/setApplicationCompanyRegNo', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
