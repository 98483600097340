<template>
  <div id="captcha">
    <VueRecaptcha
      :sitekey="sitekey"
      :loadRecaptchaScript="true"
      @verify="validate"
      @expired="expired"
    />
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import Validation from '@/captcha-validate'

export default {
  components: { VueRecaptcha },
  data() {
    return {
      sitekey: '6LfuuTccAAAAAG7AbVg7trSHoIQp0gSHw4wssKuh',
    }
  },
  methods: {
    validate(response) {
      console.log(response)
      Validation.validate(this.$siteUrl, { Response: response }).then(result => {
        console.log(result)
        this.$emit('validate', result.success)
      }).catch(error => console.log(error))
    },
    expired() {
      this.$emit('validate', false)
    },
  },
}
</script>
