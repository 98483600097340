<template>
  <div>
    <b-card>
      <b-form>
        <appPersonalInfoSection />
        <appContactInfoSection />
        <appAddressInfoSection />
        <appTradingInfoSection />
        <appSubmissionSection />
      </b-form>
    </b-card>
  </div>
</template>

<script>

import {
  BForm, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import appPersonalInfoSection from '@/components/application/applicationsections/AppPersonalInfoSection.vue'
import appContactInfoSection from '@/components/application/applicationsections/AppContactInfoSection.vue'
import appAddressInfoSection from '@/components/application/applicationsections/AppAddressInfoSection.vue'
import appTradingInfoSection from '@/components/application/applicationsections/AppTradingInfoSection.vue'
import appSubmissionSection from '@/components/application/applicationsections/AppSubmissionSection.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OperativeEditorForm',
  components: {
    BForm,
    BCard,
    appPersonalInfoSection,
    appContactInfoSection,
    appAddressInfoSection,
    appTradingInfoSection,
    appSubmissionSection,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({ applicationValidation: 'app/getApplicationValidation' }),
  },
  created() {
    this.$store.commit('app/resetApplicationValidation')
  },
  methods: {
  },
}
</script>

<style scoped>

</style>
