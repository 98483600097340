<template>
  <div>
    <div class="application-header">
      <b-img
        ref="previewEl"
        rounded
        src="@/assets/images/logo/logo.png"
        height="80"
      />
      <p class="application-header-message">
        Welcome to our job application portal.  Please fill in the information below:
      </p>
    </div>
    <applicationForm class="mr-3 ml-3" />
    <sclFooter
      slot="footer"
      class="application-footer"
    />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import applicationForm from '@/components/application/ApplicationForm.vue'
import sclFooter from '@/layouts/vertical/Footer.vue'
import { mapGetters } from 'vuex'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BImg,
    applicationForm,
    sclFooter,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentApplication' }),
  },
  created() {
    this.$store.dispatch('app/resetCurrentApplication')
    this.$store.dispatch('app/getTrades')
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
}
</script>

<style scoped>
 .application-header {
    display: flex;
    width:100%;
    margin:2em;
    margin-bottom:3em;
  }
  .application-header-message {
    margin-left: 2em;
    margin-top: auto;
    margin-bottom: 0;
  }
 .application-footer {
    display: flex;
    width:100%;
    margin:2em;
    padding-bottom:1em;
  }
</style>
